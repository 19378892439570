<template>
  <div style="width: 100%">
    <LoadingPage :visible="tableLoading"></LoadingPage>

    <div style="width: 100%" class="d-flex flex-wrap" v-show="!tableLoading">
      <v-card
        style="height: 100%; width: 100%; margin: 10px 10px 10px 10px"
        class="border-radius-md pa-3"
      >
        <div class="d-flex" style="align-items: center">
          <h3 class="text-dark mr-2" v-if="!isEdit">每日复盘</h3>
          <div>
            <DayFilter
              ref="dayFilter"
              :returnId="true"
              placeholder="选择日期"
              :autoSave="false"
              inputWidth="120px"
              class="ml-1"
              @change="changeDate"
              v-if="!isEdit"
            ></DayFilter>
            <vxe-button
              size="medium"
              content="打印"
              class="ml-2"
              status="my-primary"
              @click="$refs.reviewDisplay.print()"
              v-if="!isEdit"
            ></vxe-button>
            <vxe-button
              size="medium"
              content="编辑复盘"
              class="ml-2"
              status="my-primary"
              @click="editReview"
              v-if="!isEdit"
            ></vxe-button>
            <vxe-button
              size="medium"
              content="新建复盘"
              class="ml-2"
              status="my-primary"
              @click="addReview()"
              v-if="!isEdit"
            ></vxe-button>
            <vxe-button
              size="medium"
              content="返回预览"
              class="ml-2"
              status="my-primary"
              v-if="isEdit"
              @click="backToDisplay()"
            ></vxe-button>
            <vxe-button
              @click="AiAnalysis()"
              size="medium"
              style="width: 120px"
              class="ml-2"
              status="my-warning"
            >
              AI每日复盘
            </vxe-button>
          </div>
        </div>
        <ReviewDisplay
          v-if="!isEdit && displayContent"
          :displayContent="displayContent"
          ref="reviewDisplay"
        ></ReviewDisplay>
        <ReviewEdit v-if="isEdit" :oldContent="editContent"></ReviewEdit>
      </v-card>
    </div>
  </div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import Review from "@/api/review.js";
import ReviewDisplay from "@/components/Review/dayReview/reviewDisplay";
import ReviewEdit from "@/components/Review/dayReview/reviewEdit";
import DayFilter from "@/components/Review/dayReview/dayFilter";
import AddReview from "@/components/Review/dayReview/AddReview";
export default {
  components: { ReviewDisplay, ReviewEdit, DayFilter },
  props: {},
  data() {
    return {
      tableLoading: false,
      isEdit: false,
      dayList: [],
      selectedReviewId: null,
      displayContent: null,
      editContent: null,
    };
  },
  mounted() {},
  methods: {
    changeDate(val) {
      if (val) {
        //console.log(val);
        this.selectedReviewId = val;
        this.getReviewContent();
      }
    },
    getReviewContent() {
      this.showLoading("获取记录中，请稍候");
      Review.getReviewContent(this.selectedReviewId)
        .then((res) => {
          this.displayContent = res.data;
          //console.log(this.displayContent);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    editReview() {
      this.isEdit = true;
      this.showLoading("获取记录中，请稍候");
      Review.getReviewEdit(this.selectedReviewId)
        .then((res) => {
          this.editContent = res.data;
          //console.log(this.editContent);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    backToDisplay() {
      this.isEdit = false;
      this.getReviewContent();
    },
    addReview() {
      this.$layer.iframe({
        content: {
          content: AddReview,
          parent: this,
          data: {
            iframeData: {},
          },
        },
        area: ["600px", "600px"],
        title: "新建复盘",
        maxmin: false,
        shade: true,
        shadeClose: false,
        cancel: () => {
          //关闭弹窗事件
        },
      });
    },
    AiAnalysis() {
      let reviewId = this.selectedReviewId;
      let day = "";
      let DayArr = this.$refs.dayFilter.selectData;
      for (let i = 0; i < DayArr.length; i++) {
        if (DayArr[i].value == reviewId) {
          day = DayArr[i].label;
          break;
        }
      }
      this.$commonHelpers.openAiAnalysisIframe(
        "AI复盘",
        "https://chatgpt.lhotsetech.com/chat/share?shareId=slpz40ocditis96qxsm315yf",
        this
      );
    },
  },
};
</script>
<style scoped="scoped"></style>
